<template>
<div class="login">
  <v-card
    class="mx-auto"
    style="max-width: 500px;"
    v-if="loginShow"
  >
    <v-toolbar
      color="deep-gray accent-4"
      cards
      dark
      flat
    >
      <v-btn to="/hello" icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-card-title class="text-h6 font-weight-regular">
        로그인
      </v-card-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form
      ref="form"
      v-model="form"
      class="pa-4 pt-6"
    >
      <v-text-field
        v-model="credentials.username"
        filled
        color="deep-purple"
        counter="20"
        label="사용자ID"
        style="min-height: 96px"
      ></v-text-field>
      <v-text-field
        v-model="credentials.password"
        filled
        color="deep-purple"
        counter="8"
        label="비밀번호"
        style="min-height: 96px"
        type="password"
      ></v-text-field>
    </v-form>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        text
        @click="$refs.form.reset()"
      >
        Clear
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!form"
        :loading="isLoading"
        class="white--text"
        color="deep-purple accent-4"
        depressed
        @click="login"
      >
        로그인
      </v-btn>
    </v-card-actions>    
  </v-card>
    <v-alert
    dense
    outlined
    type="error"
    v-if="loginFailShow"
    >
    아직 <strong>가입 승인</strong>이 안되었거나, <strong>사용자 ID</strong> 또는 <strong>비밀번호</strong>가 맞지 않습니다!
    </v-alert>

 </div>
</template> 

<script> 
import axios from 'axios';
import {mapState, mapActions, mapMutations} from 'vuex';

// const SERVER_URL = process.env.VUE_APP_SERVER_URL

export default {
  name: 'Login',
  data: function () {
    return {
      credentials : {
        username : null,
        password : null,
      },
      loginShow: true,
      dialog: false,
      form: false,
      isLoading: false,
      loginFailShow: false,
    }
  },
  methods: {
    ...mapMutations(['setLogin', 'setLogout', 'setUser']),
    
    login() {
      var vm = this;
      axios.post('/api/token/', this.credentials) 
                .then(function(res) {
                    vm.loginFailShow = false
                    // vueex state에 user, login 정보 저장
                    vm.setUser({'user': vm.credentials.username, 'avatar': vm.credentials.username.substr(0,2)})
                    vm.setLogin({'isLogin': true})
                    vm.setLogout({'isLogout': false})
                    // 로컬스토리지에 토큰 저장
                    localStorage.setItem('jwt', res.data.token)
                    // Home Page로 이동
                    vm.$router.push({ name : 'HelloTony'})
                })
                .catch(function (err) {
                    if (err.response.status == 400) vm.loginFailShow = true
                    
                });
    },

  }
}
</script>


<style>
.login{
   padding: 10px 10px;
   margin-top: 10px;
}
</style>
